import request from "./request.service";

export default {
  getConsumerInfo: async function (consumer_id) {
    try {
      const response = await request(`/icoke/info/${consumer_id}`);
      return response.data;
    } catch (err) {
      console.log(err);
    }
  },
  getConsumerTan: async function (consumer_id) {
    try {
      const response = await request(`/icoke/tan/${consumer_id}`);
      return response.data;
    } catch (err) {
      console.log(err);
    }
  },
  getConsumerCoupon: async function (consumer_id, offset, limit) {
    try {
      const response = await request(
        `/icoke/coupon/${consumer_id}/${offset}/${limit}`
      );
      return response.data.data;
    } catch (err) {
      console.log(err);
    }
  },
  getConsumerCode: async function (mobile, offset, limit) {
    try {
      const response = await request(
        `/icoke/code/${mobile}/${offset}/${limit}`
      );
      return response.data.data;
    } catch (err) {
      console.log(err);
    }
  },
};
