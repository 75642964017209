import { useEffect, useState } from "react";
import icokeService from "services/icoke.service";
import { Container, Row, Col, Card, Table } from "react-bootstrap";
import Pagination from "components/Util/Pagination";
import Loader from "react-spinners/BarLoader";

export default function ICoke(props) {
  const [codePagination, setcodePagination] = useState();
  const [couponPagination, setcouponPagination] = useState();

  const [consumerInfo, setconsumerInfo] = useState();
  const [couponList, setcouponList] = useState([]);
  const [codeList, setcodeList] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(async () => {
    if (props.consumer?._id) {
      try {
        setLoading(true);
        const info = await icokeService.getConsumerInfo(props.consumer?._id);
        const code = await icokeService.getConsumerCode(
          props.consumer?.mobile,
          0,
          10
        );
        const coupon = await icokeService.getConsumerCoupon(
          props.consumer?._id,
          0,
          10
        );
        // const tan = await icokeService.getConsumerTan(props.consumer?._id);

        setconsumerInfo(info.data);
        setcodeList(code?.list);
        setcodePagination(code?.pagination);
        setcouponList(coupon?.list);
        setcouponPagination(coupon?.pagination);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    }
  }, [props?.consumer]);

  if (!props.consumer || !props.consumer.profile) {
    return <h4>Хэрэглэгчийн мэдээлэл байхгүй байна.</h4>;
  }

  return (
    <>
      <Row>
        <Col md="6">
          <Card className="striped-tabled-with-hover">
            <Card.Header>
              <Card.Title as="h4">Хэрэглэгч</Card.Title>
            </Card.Header>
            <Card.Body className="table-full-width table-responsive px-0">
              <Col md="12">
                <Table
                  className="table-hover"
                  style={{
                    fontSize: "12px",
                  }}
                >
                  {consumerInfo ? (
                    <>
                      <thead>
                        <tr>
                          <th className="border-0">Үүссэн огноо</th>
                          <th className="border-0">ICoke дугаар</th>
                          <th className="border-0">Статус</th>
                          <th className="border-0">Цуцалсан огноо</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{consumerInfo?.created_at}</td>
                          <td>{consumerInfo?.mobile}</td>
                          <td>{consumerInfo?.status}</td>
                          <td>{consumerInfo?.disconnected_at}</td>
                        </tr>
                      </tbody>
                    </>
                  ) : (
                    <> Хэрэглэгч урамшуулалд хамрагдаагүй байна</>
                  )}
                </Table>
              </Col>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Card className="striped-tabled-with-hover">
            <Card.Header>
              <Card.Title as="h4">Бөглөөний дугаарууд</Card.Title>
            </Card.Header>
            <Card.Body className="table-full-width table-responsive px-0">
              <Col md="12">
                <Table
                  className="table-hover"
                  style={{
                    fontSize: "12px",
                  }}
                >
                  <thead>
                    <tr>
                      <th className="border-0">Үүссэн огноо</th>
                      <th className="border-0">Дугаар</th>
                      <th className="border-0">Код</th>
                      <th className="border-0">Статус</th>
                      <th className="border-0">Баталгаажсан огноо</th>
                      <th className="border-0">Ашигласан эсэх</th>
                      <th className="border-0">Ашигласан огноо</th>
                    </tr>
                  </thead>
                  <tbody>
                    {codeList.map((code, ind) => {
                      return (
                        <tr key={ind}>
                          <td>{code?.created_at}</td>
                          <td>{code?.mobile}</td>
                          <td>{code?.confirm_code}</td>
                          <td>
                            {code?.status == "REDEEMED"
                              ? "Ашигласан"
                              : code?.status == "CONFIRMED"
                              ? "Баталгаажсан"
                              : "Шинэ"}
                          </td>
                          <td>{code?.confirmed_at}</td>
                          <td>{code?.redeemed_at ? "Тийм" : "Үгүй"}</td>
                          <td>{code?.redeemed_at}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                <Pagination
                  pagination={codePagination}
                  onChange={async (offset, limit) => {
                    const code = await icokeService.getConsumerCode(
                      props?.consumer?._id,
                      offset,
                      limit
                    );
                    setcodeList(code?.list);
                    setcodePagination(code?.pagination);
                  }}
                />
              </Col>
            </Card.Body>

            {loading ? <Loader /> : null}
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Card className="striped-tabled-with-hover">
            <Card.Header>
              <Card.Title as="h4">Купон</Card.Title>
            </Card.Header>
            <Card.Body className="table-full-width table-responsive px-0">
              <Col md="12">
                <Table
                  className="table-hover"
                  style={{
                    fontSize: "12px",
                  }}
                >
                  <thead>
                    <tr>
                      <th className="border-0">Үүссэн огноо</th>
                      <th className="border-0">Ундааны тоо</th>
                      <th className="border-0">Купоны нэр</th>
                      <th className="border-0">Ашигласан эсэх</th>
                      <th className="border-0">Ашигласан огноо</th>
                      <th className="border-0">Дуусах огноо</th>
                    </tr>
                  </thead>
                  <tbody>
                    {couponList?.map((coupon, ind) => {
                      return (
                        <tr key={ind}>
                          <td>{coupon?.created_at}</td>
                          <td>{coupon?.batch_count}</td>
                          <td>{coupon?.coupon_name}</td>
                          <td>{coupon?.is_redeemed ? "Тийм" : "Үгүй"}</td>
                          <td>{coupon?.redeemed_at}</td>
                          <td>{coupon?.expire_date}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                <Pagination
                  pagination={couponPagination}
                  onChange={async (offset, limit) => {
                    const coupon = await icokeService.getConsumerCoupon(
                      props?.consumer?._id,
                      offset,
                      limit
                    );
                    setcouponList(coupon?.list);
                    setcouponPagination(coupon?.pagination);
                  }}
                />
              </Col>
            </Card.Body>
            {loading ? <Loader /> : null}
          </Card>
        </Col>
      </Row>
    </>
  );
}
