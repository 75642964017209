import { useEffect, useState } from "react";
import {
  Row,
  Col,
  Table,
  Card,
  Container,
  Form,
  Button,
} from "react-bootstrap";
import { getLastWeekDate, getCurrentDate } from "helpers/dateHelper";
import Pagination from "components/Util/Pagination";
import collectiveService from "services/collective.service";
import Loader from "react-spinners/BarLoader";

export default function FamilyEvent(props) {
  const { consumer } = props;

  const [startDate, setStartDate] = useState(getLastWeekDate());
  const [endDate, setEndDate] = useState(getCurrentDate());
  const [familyId, setFamilyId] = useState("");
  const [consum, setConsumer] = useState("");

  const [pagination, setPagination] = useState();

  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setConsumer(consumer.mobile);
  }, [props]);

  const handleSearch = async (offset = 0, limit = 10) => {
    try {
      setEvents([]);
      setLoading(true);
      const result = await collectiveService.FamilyEvents(
        consumer._id,
        consum.trim(),
        familyId.trim(),
        startDate,
        endDate,
        offset,
        limit
      );
      setEvents(result.list);
      setPagination(result.pagination);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Col md="12">
      <Card>
        {loading ? <Loader /> : null}
        <Card.Header>
          <Card.Title as="h4">Family Events</Card.Title>
          <Form>
            <Form.Row className="align-items-center">
              <Form.Group as={Col} sm="2" controlId="formGridEmail">
                <Form.Label>Эхлэх огноо</Form.Label>
                <Form.Control
                  type="date"
                  format="yyyy-mm-dd"
                  value={startDate}
                  onChange={(e) => {
                    setStartDate(e.target.value);
                  }}
                />
              </Form.Group>

              <Form.Group as={Col} sm="2" controlId="formGridPassword">
                <Form.Label>Дуусах огноо</Form.Label>
                <Form.Control
                  type="date"
                  format="yyyy-mm-dd"
                  placeholder={endDate || "дуусах огноо"}
                  value={endDate}
                  onChange={(e) => {
                    setEndDate(e.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Хэрэглэгч</Form.Label>
                <Form.Control
                  type="number"
                  value={consum}
                  onChange={(e) => {
                    setConsumer(e.target.value);
                  }}
                ></Form.Control>
              </Form.Group>

              <Form.Group as={Col} sm="2" controlId="formGridPassword">
                <Form.Label>Коллектив ID</Form.Label>
                <Form.Control
                  type="number"
                  value={familyId}
                  onChange={(e) => {
                    setFamilyId(e.target.value);
                  }}
                ></Form.Control>
              </Form.Group>

              <Form.Group as={Col} sm="1" controlId="formGridPassword">
                <Button
                  style={{ marginTop: "20px" }}
                  variant="primary"
                  className="btn btn-sm"
                  size="md"
                  onClick={() => {
                    handleSearch(0, 10);
                  }}
                >
                  Хайх
                </Button>
              </Form.Group>
            </Form.Row>
          </Form>
        </Card.Header>
        <Card.Body>
          <Table>
            <thead>
              <tr>
                <th>Огноо</th>
                <th>Event нэр</th>
                <th>Family Id</th>
                <th>Дугаар</th>
              </tr>
            </thead>
            <tbody>
              {events.length ? (
                events.map((e) => (
                  <tr>
                    <td>{e.created_at}</td>
                    <td>{e.event_name}</td>
                    <td>{e.family_id}</td>
                    <td>{e.mobile}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={4}>Тус хугацаанд үйлдэл байхгүй байна</td>
                </tr>
              )}
            </tbody>
          </Table>
          <Pagination
            pagination={pagination}
            onChange={(offset, limit) => {
              handleSearch(offset, limit);
            }}
          />
        </Card.Body>
      </Card>
    </Col>
  );
}
