import request from "./request.service";
export default {
  getCollective: async function (consumerId) {
    try {
      const response = await request(
        "/collective/find_by_consumer_id",
        "POST",
        { consumer_id: `${consumerId}` }
      );
      if (response.data.code === 0) {
        return response.data.data;
      } else {
        throw Error(response.data.message);
      }
    } catch (err) {
      throw err;
    }
  },
  getCollectiveByFamilyId: async function (collective_id) {
    try {
      const response = await request(
        "/collective/find_by_collective_id",
        "POST",
        {
          collective_id: collective_id,
        }
      );
      if (response.data.code === 0) {
        return response.data.data;
      } else {
        throw Error(response.data.message);
      }
    } catch (err) {
      throw err;
    }
  },
  removeMember: async function (groupId, consumerId, note) {
    try {
      const response = await request("/collective/remove_member", "POST", {
        group_id: groupId.toString(),
        consumer_id: consumerId.toString(),
        note: note,
      });
      if (response.data.code === 0) {
        return response.data.data;
      } else {
        throw Error(response.data.message);
      }
    } catch (err) {
      throw err;
    }
  },
  removeMemberFees: async function (groupId, consumerId, note) {
    try {
      const response = await request(
        "/collective/remove_member_with_fee",
        "POST",
        {
          group_id: groupId.toString(),
          consumer_id: consumerId.toString(),
          note: note,
        }
      );
      if (response.data.code === 0) {
        return response.data.data;
      } else {
        throw Error(response.data.message);
      }
    } catch (err) {
      throw err;
    }
  },
  addMember: async function (familyId, consumerId, note) {
    try {
      const response = await request("/collective/add_member", "POST", {
        family_id: familyId.toString(),
        consumer_id: consumerId.toString(),
        note: note,
      });
      if (response.data.code === 0) {
        return response.data.data;
      } else {
        throw Error(response.data.message);
      }
    } catch (err) {
      throw err;
    }
  },

  makeAdmin: async function (groupId, consumerId, note) {
    try {
      const response = await request("/collective/make_admin", "POST", {
        group_id: groupId.toString(),
        consumer_id: consumerId.toString(),
        note: note,
      });
      if (response.data.code === 0) {
        return response.data.data;
      } else {
        throw Error(response.data.message);
      }
    } catch (err) {
      throw err;
    }
  },

  checkHamtdaa: async function (familyId) {
    try {
      const response = await request("/collective/check_hamtdaa", "POST", {
        family_id: familyId.toString(),
      });
      return response.data;
    } catch (err) {
      throw err;
    }
  },

  syncHamtdaa: async function (familyId) {
    try {
      const response = await request("/collective/sync_hamtdaa", "POST", {
        family_id: familyId.toString(),
      });
      return response.data;
    } catch (err) {
      throw err;
    }
  },
  FamilyEvents: async function (
    consumerId,
    mobile = "",
    familyId = "",
    startDate,
    endDate,
    offset = 0,
    limit = 10
  ) {
    try {
      if (!mobile && !familyId) {
        throw Error("Утасны эсвэл Family дугаар олдсонгүй");
      }
      const response = await request(
        `/collective/family_events?consumer_id=${consumerId}&mobile=${mobile}&family_id=${familyId}&start_date=${startDate}&end_date=${endDate}&offset=${offset}&limit=${limit}`
      );
      return response.data.data;
    } catch (err) {
      throw err;
    }
  },
};
