import request from "./request.service";
import { getStartOfDate, getEndOfDate } from "../helpers/dateHelper";
export default {
  getConsumerCoupons: async function (consumerId, offset, limit) {
    try {
      let body = {
        consumer_id: `${consumerId}`,
        offset: offset,
        limit: limit,
      };
      const response = await request("/promo/coupon_list", "POST", body);
      if (response.data.code === 0) {
        return response.data.data;
      } else {
        throw Error(response.data.message);
      }
    } catch (err) {
      throw err;
    }
  },

  checkCoupon: async function (couponCode) {
    try {
      let body = {
        coupon_code: couponCode,
      };
      const response = await request("/promo/coupon_check", "POST", body);
      if (response.data.code === 0) {
        return response.data.data;
      } else {
        throw Error(response.data.message);
      }
    } catch (err) {
      throw err;
    }
  },
  giftCoupon: async function (consumerId, offset, limit, type) {
    try {
      const response = await request(
        `/promo/coupon_gift?consumer_id=${consumerId}&limit=${limit}&offset=${offset}&type=${type}`,
        "GET",
        {}
      );
      if (response.data.code === 0) {
        return response.data.data;
      } else {
        throw Error(response.data.message);
      }
    } catch (err) {
      throw err;
    }
  },

  getSpinPromotions: async function () {
    try {
      let body = {};
      const response = await request("/promo/spin/promotions", "GET", body);
      if (response.data.code === 0) {
        return response.data.data;
      } else {
        throw Error(response.data.message);
      }
    } catch (err) {
      throw err;
    }
  },

  getConsumerSpins: async function (
    consumerId,
    startDate,
    endDate,
    promotionId
  ) {
    try {
      let startPretty = getStartOfDate(startDate);
      let endPretty = getEndOfDate(endDate);
      let body = {
        consumer_id: `${consumerId}`,
        start_date: startPretty,
        end_date: endPretty,
        promotion_id: promotionId,
      };
      const response = await request("/promo/spin/spins", "POST", body);
      if (response.data.code === 0) {
        return response.data.data;
      } else {
        throw Error(response.data.message);
      }
    } catch (err) {
      throw err;
    }
  },
  issueSpinChance: async function (
    consumerId,
    promotionId,
    ticketNumber,
    chanceCount,
    note
  ) {
    try {
      let body = {
        consumer_id: `${consumerId}`,
        promotion_id: promotionId,
        ticket_number: ticketNumber,
        chance_count: chanceCount,
        note: note,
      };
      const response = await request("/promo/spin/issue", "POST", body);
      if (response.data.code === 0) {
        return response.data.data;
      } else {
        throw Error(response.data.message);
      }
    } catch (err) {
      throw err;
    }
  },

  // springGetHints: async function (consumerId) {
  //   try {
  //     let body = {};
  //     const response = await request(
  //       `/promo/spring/hints?consumer_id=${consumerId}`,
  //       "GET",
  //       body
  //     );
  //     if (response.data.statusCode === 0) {
  //       return response.data.data;
  //     } else {
  //       throw Error(response.data.message);
  //     }
  //   } catch (err) {
  //     throw err;
  //   }
  // },

  // springGetTokens: async function (consumerId) {
  //   try {
  //     let body = {};
  //     const response = await request(
  //       `/promo/spring/tokens?consumer_id=${consumerId}`,
  //       "GET",
  //       body
  //     );
  //     if (response.data.statusCode === 0) {
  //       return response.data.data;
  //     } else {
  //       throw Error(response.data.message);
  //     }
  //   } catch (err) {
  //     throw err;
  //   }
  // },
  // springGetHistory: async function (consumerId) {
  //   try {
  //     let body = {};
  //     const response = await request(
  //       `/promo/spring/history?consumer_id=${consumerId}`,
  //       "GET",
  //       body
  //     );
  //     if (response.data.statusCode === 0) {
  //       return response.data.data;
  //     } else {
  //       throw Error(response.data.message);
  //     }
  //   } catch (err) {
  //     throw err;
  //   }
  // },
  getBadges: async function (consumerId) {
    try {
      let body = {};
      const response = await request(`/promo/badge/badges`, "GET", body);
      if (response.data.code === 0) {
        return response.data.data;
      } else {
        throw Error(response.data.message);
      }
    } catch (err) {
      throw err;
    }
  },
  consumerBadges: async function (consumerId) {
    try {
      let body = {};
      const response = await request(
        `/promo/badge/list?consumer_id=${consumerId}`,
        "GET",
        body
      );
      if (response.data.code === 0) {
        return response.data.data;
      } else {
        throw Error(response.data.message);
      }
    } catch (err) {
      throw err;
    }
  },
  consumerBadgesHistory: async function (
    consumerId,
    badgeId,
    startDate,
    endDate,
    offset = 0,
    limit = 10
  ) {
    try {
      let body = {
        consumer_id: `${consumerId}`,
        badge_id: badgeId,
        start_date: startDate,
        end_date: endDate,
        offset: offset,
        limit: limit,
      };
      console.log(body);
      const response = await request(`/promo/badge/history`, "POST", body);
      if (response.data.code === 0) {
        return response.data.data;
      } else {
        throw Error(response.data.message);
      }
    } catch (err) {
      throw err;
    }
  },
};
