import request from "./request.service";
import axios from "axios";

export default {
  removeCard: async function (
    partner_name,
    card_number,
    mobile,
    ticket_number
  ) {
    try {
      const response = await request(`/card/disconnect`, "POST", {
        partner_name: partner_name,
        card_number: card_number,
        mobile: mobile,
        ticket_number: ticket_number,
      });
      if (response.data.code === 0) {
        return response.data.data;
      } else {
        throw Error(response.data.message);
      }
    } catch (err) {
      throw err;
    }
  },
  connectCard: async function (consumer_id, card_number, ticket_number) {
    try {
      console.log({
        consumer_id: consumer_id,
        card_number: card_number,
        ticket_number: ticket_number,
      });
      const response = await request(`/card/connect`, "POST", {
        consumer_id: `${consumer_id}`,
        card_number: card_number,
        ticket_number: ticket_number,
      });
      if (response.data.code === 0) {
        return response.data.data;
      } else {
        throw Error(response.data.message);
      }
    } catch (err) {
      throw err;
    }
  },
  createPartnerCard: async function (consumer_id, card_number) {
    try {
      if (!card_number) {
        throw new Error("Картын дугаар оруулна уу");
      }
      const result = await request("/card/create_partner_card", "POST", {
        consumer_id: consumer_id,
        card_number: card_number,
      });
      if (result.code !== 200) {
        throw new Error(result.data.message);
      }
      return result.data;
    } catch (err) {
      throw err;
    }
  },
};
